<template>
  <div id="modelindex">
    <!--内容区域布局-->
    <LiefengContent>
      <!--功能名称，定义则显示-->
      <template v-slot:title>OEM信息管理</template>
       <!--左则工具条-->
      <template v-slot:toolsbarLeft>
 
                <Input
                  enter-button
                  v-model.trim="searchData.name"
                  placeholder="OEM名称/域名"
                  style="width: 250px;margin:0 5px;"
                  @on-enter="getList"
                />
              <Button type="info" icon="ios-search" @click="getList">查询</Button>

      </template>
       <!--右则工具条-->
      <template v-slot:toolsbarRight>
        <Button type="primary" icon="ios-add-circle-outline" @click="showModal=true">新增</Button>
        <Button type="warning" icon="ios-mail">删除</Button>
        <Button type="success" icon="ios-search">启禁用</Button>
      </template>
       <!--内容区域-->
      <template v-slot:contentArea>
         <!--表格应用-->
        <LiefengTable
          :talbeColumns="talbeColumns"
          :tableData="tableData"
          height="200" 
          :loading="loading"
          :fixTable="true"
          :curPage="pageNum"
          :total="total"
          :page-size="pageSize"
          @tableSelect="setSelection"
          @tableSort="tableSort"
          @hadlePageSize="hadlePageSize"
          
        >
        </LiefengTable>
      </template>
    </LiefengContent>

<LiefengModal
     v-model="showModal"
      title="发消323232323"
      width="80%"
      height="calc(100vh - 250px)"
    >
    <!--工具条-->
    <template #toolsbar>
       <Button type="primary">确定</Button>
       <Button style="margin-left: 10px">清空</Button>
    </template>
    <!--内容-->
       <template #contentarea>
      <modelFrom :msgform="msgform"></modelFrom>
    </template>
</LiefengModal>
  
  </div>
</template>
<script>
import LiefengContent from "@/components/LiefengContent.vue";
import LiefengTable from "@/components/LiefengTable.vue";
import LiefengModal from "@/components/LiefengModal.vue";
import modelFrom from "@/views/model/childrens/modelForm.vue";
export default {
  components: {
    LiefengContent,
    LiefengTable,
    LiefengModal,
    modelFrom,
  },
  data() {
    return {
      loading:false,
      total: 0,
      treeData: [],
      showModal:false,
      msgform: {
        businessCode: "0000", //业务代码 0000:发送系统信息
        businessType: 5, // 业务类型5:发送系统信息
        oemCode: parent.vue.loginInfo.userinfo.oemCode
          ? parent.vue.loginInfo.userinfo.oemCode
          : "zjsm",
        recIds: "",
        title: "",
        content: "",
        sendId: parent.vue.loginInfo.userinfo.custGlobalId
          ? parent.vue.loginInfo.userinfo.custGlobalId
          : "C191106165056ff808181920463",
      },
      talbeColumns: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "登录页LOGO",
          key: "loginlogo",
          width:150,
        },
        {
          title: "OEM代码",
          key: "oemcode",
          width: 200,
          align: "center",
        },
        {
          title: "OEM门户名称",
          key: "oemname",
          render: (h, params) => {
            return h("div", 
                "<button>fd</button>"+params.row.oemname
            );
          },
        },
        {
          title: "版权信息",
          key: "copyright",
          align: "center",
        },
        {
          title: "操作",
          width: 120,
          render: (h, params) => {
            return h("Button", 
                {
                  props:{type:"primary",size:"small"},
                  style:{},
                  on:{

                  }
                }
            ,"删除");
          },
        },
      ],
      tableData: [],
      selection: [],
      pageNum: 1,
      pageSize: 3,
      orgCode: "",
      searchData: {
        name: "",
        phone: "",
        pageSize: 3,
        pageNum: 1,
      },
    
      pagess: 1,
    };
  },
  created() {},
  mounted() {
  },
  watch: {
    
  },
  methods: {
    getList(data) {
      if (data) {
        if (data.communityCode) {
          this.tableData = [];
          this.pageNum = 1;
          this.treeParams = {
            ...data,
            status: 2, //必填
            name: this.searchData.name,
            phone: this.searchData.phone,
            oemCode: parent.vue.oemInfo.oemCode,
            page: data.page,
            pageSize: data.pageSize,
          };
          this.pageNum = data.page;
          this.pageSize = data.pageSize;
        }
        if (data.page) {
          //切换页面时
          this.treeParams.page = data.page;
          this.treeParams.pageSize = data.pageSize;
          this.treeParams.name = this.searchData.name;
          this.treeParams.phone = this.searchData.phone;
          // this.treeParams.name = this.searchData.name;
          // this.treeParams.phone = this.searchData.phone;
        }
        if (data.cancelable) {
          //点击查询时  查询返回第一页的数据
          this.treeParams.page = 1;
          this.treeParams.name = this.searchData.name;
          this.treeParams.phone = this.searchData.phone;
          this.pageNum = 1;
        }
      } else {
        this.treeParams = {
          communityCode: this.treeParams.communityCode,
          roadCode: this.treeParams.roadCode,
          status: 2, //必填
          name: this.searchData.name,
          phone: this.searchData.phone,
          oemCode:parent.vue.oemInfo.oemCode,
          page: this.pageNum,
          pageSize: this.pageSize,
        };
      }
      this.loading=true;
      // console.log(this.treeParams)
      this.$get(
        "/syaa/pc/sy/user/residents/selectSyFamilyMemberPage",
        this.treeParams
      )
        .then((res) => {
          this.loading=false;
          if (res.code === "200") {
            this.tableData = res.dataList;
            this.total = res.maxCount;
            this.pageNum = res.currentPage;
            this.treeParams.name = "";
            this.treeParams.phone = "";
          }
        })
        .catch((err) => {
          this.loading=false;
          console.log(err);
        });
    },

    setSelection(selection) {
      console.log(selection);
      this.selection = selection;
    },
    tableSort(sortdata){
       console.log(sortdata);
    },
        hadlePageSize(val) {
          console.log(val);
          this.getList(val);
        },
    
    getTableData(data) {
      this.tableData = data.dataList;
      this.total = data.maxCount;
    },


  },
};
</script>

<style lang="less" scoped>

form input,form button{
margin:0 5px;
}
</style>
