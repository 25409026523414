<template>
<Form
        :model="msgform"
        :label-width="80"
        ref="msgform"
        :rules="ruleValidate"
      >
        <formItem
          ><Button type="primary" @click="selectUser"
            >选择接收人</Button
          ></formItem
        >
        <FormItem label="接收人" prop="recIds"
          ><Input
            disabled
            v-model="selectName"
            type="textarea"
            :rows="2"
            placeholder="请选择接收人"
          ></Input
        ></FormItem>
        <FormItem label="标题" prop="title"
          ><Input
            v-model="msgform.title"
            placeholder="请输入标题"
            maxlength="30"
            show-word-limit
          ></Input
        ></FormItem>
        <FormItem label="内容" prop="content"
          ><Input
            v-model="msgform.content"
            type="textarea"
            maxlength="800"
            show-word-limit
            :rows="8"
            placeholder="请输入内容"
          ></Input
        ></FormItem>

      </Form>
</template>

<script>
export default {
     props:{
    msgform:Object,
  }
};
</script>